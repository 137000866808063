import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')
];

export const server_loads = [0,13,3,4,5,7,9,11];

export const dictionary = {
		"/": [15],
		"/(admin)/account_admin": [~16,[2]],
		"/(user)/account": [~50,[14]],
		"/auth": [52],
		"/auth/reset_password": [53],
		"/auth/sign_in": [54],
		"/auth/sign_out": [~55],
		"/auth/sign_up": [56],
		"/auth/update_password": [~57],
		"/(user)/chat/[team_id]": [~51,[14]],
		"/(admin)/teams": [48,[2,13]],
		"/(admin)/teams/[team_id]": [~49,[2,13]],
		"/(admin)/t/[team_id]/bots": [~17,[2,3]],
		"/(admin)/t/[team_id]/bots/new": [28,[2,3]],
		"/(admin)/t/[team_id]/bots/[bot_id]/config": [18,[2,3,4]],
		"/(admin)/t/[team_id]/bots/[bot_id]/connections": [19,[2,3,4,5]],
		"/(admin)/t/[team_id]/bots/[bot_id]/connections/discord": [20,[2,3,4,5,6]],
		"/(admin)/t/[team_id]/bots/[bot_id]/connections/discord/[conn_id]/conn": [~21,[2,3,4,5,6,7]],
		"/(admin)/t/[team_id]/bots/[bot_id]/connections/discord/[conn_id]/servers": [~22,[2,3,4,5,6,7]],
		"/(admin)/t/[team_id]/bots/[bot_id]/connections/webchat/[conn_id]": [23,[2,3,4,5,8,9]],
		"/(admin)/t/[team_id]/bots/[bot_id]/connections/whatsapp": [24,[2,3,4,5,10]],
		"/(admin)/t/[team_id]/bots/[bot_id]/connections/whatsapp/[conn_id]": [25,[2,3,4,5,10,11]],
		"/(admin)/t/[team_id]/bots/[bot_id]/knowledge": [~26,[2,3,4]],
		"/(admin)/t/[team_id]/bots/[bot_id]/link_knowledge_source": [~27,[2,3,4]],
		"/(admin)/t/[team_id]/chat": [~29,[2,3]],
		"/(admin)/t/[team_id]/conversations": [~30,[2,3]],
		"/(admin)/t/[team_id]/conversations/bots/[bot_id]": [~32,[2,3]],
		"/(admin)/t/[team_id]/conversations/[conversation_id]/chat": [~31,[2,3]],
		"/(admin)/t/[team_id]/knowledge_sources": [~33,[2,3]],
		"/(admin)/t/[team_id]/knowledge_sources/new": [38,[2,3]],
		"/(admin)/t/[team_id]/knowledge_sources/new/(new_flow)/database": [~39,[2,3,12]],
		"/(admin)/t/[team_id]/knowledge_sources/new/(new_flow)/document": [~40,[2,3,12]],
		"/(admin)/t/[team_id]/knowledge_sources/new/(new_flow)/faq": [~41,[2,3,12]],
		"/(admin)/t/[team_id]/knowledge_sources/new/(new_flow)/text": [~42,[2,3,12]],
		"/(admin)/t/[team_id]/knowledge_sources/new/(new_flow)/website": [~43,[2,3,12]],
		"/(admin)/t/[team_id]/knowledge_sources/[knowledge_source_id]": [~34,[2,3]],
		"/(admin)/t/[team_id]/knowledge_sources/[knowledge_source_id]/database": [~35,[2,3]],
		"/(admin)/t/[team_id]/knowledge_sources/[knowledge_source_id]/edit": [~36,[2,3]],
		"/(admin)/t/[team_id]/knowledge_sources/[knowledge_source_id]/faq": [~37,[2,3]],
		"/(admin)/t/[team_id]/tags": [44,[2,3]],
		"/(admin)/t/[team_id]/tags/training": [~45,[2,3]],
		"/(admin)/t/[team_id]/users": [~46,[2,3]],
		"/(admin)/t/[team_id]/users/new": [47,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';